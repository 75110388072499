import React from 'react';
import './collection.scss';
import Poem from './poem';

function Collection({ poems, closeCollection }) {
  return (
    <div className="collection">
      <div className="collection__nav">
        <button onClick={closeCollection} className='collection__close'>Close</button>
      </div>
      <div className="collection__poems">
        <div className='poem poem--cover'>
            <h1>Collection One</h1>
            <h2>August 2024</h2>
        </div>
        {poems.map((poem, index) => (
          <Poem
            title={poem.title ? poem.title : undefined}
            subtitle={poem.subtitle ? poem.subtitle : undefined}
            poem={poem.text ? poem.text.split('\n') : undefined}
            pageNumber={index + 1}
          />
        ))}
      </div>
    </div>
  );
}

export default Collection;
