
function PrintView({setPrintView}) {
    
return (<div className="print-view">
    <div className="print-view__toolbar">
      <button className="print-view__close" onClick={() => setPrintView(false)}>
        Close
      </button>
    </div>

    <p className="print-view__instructions">Right click to save image</p>
  </div>)
}
export default PrintView