import React, { useState } from 'react';
import './poem.scss';

function Poem({ title, subtitle, poem, pageNumber, makeActiveLine, activeLineNo, editable = false, deleteWord, capitaliseWord }) {
  const [showEditWord, setShowEditWord] = useState({ line: undefined, word: undefined });
  const showEditWordFn = (e, line, word) => {
    e.stopPropagation();
    setShowEditWord({ line: line, word: word });
    makeActiveLine(e, line)
  };
  return (
    <div className="poem" onClick={editable ? (e)=> showEditWordFn(e, poem.length-1, undefined) : null}>
      {title && <h1 className="poem__title">{title}</h1>}
      {subtitle && <h2 className="poem__subtitle">{subtitle}</h2>}
      {poem &&
        poem.map((line, index) =>
          editable ? (
            <p
              key={`line--${index}`}
              onClick={editable ? (e) => makeActiveLine(e, index) : null}
              className={`poem__line  ${index === activeLineNo ? 'poem__line--active' : ''}`}
              data-umami-event="make-line-active"
            >
              <span className="ellipsis">...</span>
              {line.map((word, i) =>
                word !== ' ' ? (
                  <span key={`word--${i}`} className={`poem__word ${showEditWord && index === showEditWord.line && i === showEditWord.word ? 'poem__word--highlighted' : ''}`} data-umami-event="edit-word" data-umami-event-value={word} onClick={(e) => showEditWordFn(e, index, i)}>
                    {word}
                    {showEditWord && index === showEditWord.line && i === showEditWord.word && (
                      <span className="poem__word-edit">
                        <button data-umami-event="delete-word" data-umami-event-value={word} onClick={() => deleteWord(index, i)}>Delete word</button>
                        <button data-umami-event="capitalise-word" data-umami-event-value={word}onClick={()=> capitaliseWord(index, i)}>Capitalise</button>
                        <button data-umami-event="lowercase-word" data-umami-event-value={word}onClick={()=> capitaliseWord(index, i, true)}>Lowercase</button>
                        <button data-umami-event="close-edit-word" data-umami-event-value={word}onClick={(e) => showEditWordFn(e, index, undefined)}>Done</button>
                      </span>
                    )}
                  </span>
                ) : (
                  word
                )
              )}
              <span className="cursor">|</span>
            </p>
          ) : (
            <p key={index} className="poem__line">
              {line}
            </p>
          )
        )}
      {pageNumber && <div className="poem__number">{pageNumber}</div>}
      {<span className="poem__watermark">poetica.bdlnds.co.uk</span>}
    </div>
  );
}

export default Poem;
