import React, { useEffect, useState } from 'react';
import './poetry-remixer.scss';
import html2canvas from 'html2canvas';
import examplePoems from '../data/poems.json';
import collectionOne from '../data/collectionOne.json';
import february from '../data/February.json';
import Poem from './poem';
import Collection from './collection';
import PrintView from './print-view';
import OptionsDropdown from './options-dropdown';

function PoetryRemixer() {
  const [textareaValue, setTextareaValue] = useState('');
  const [hideTextarea, setHideTextArea] = useState(false);
  const [poemElements, setPoemElements] = useState([]);
  const [newPoem, setNewPoem] = useState();
  const [activeLineNo, setActiveLineNo] = useState(0);
  const [capitaliseNextWord, setCapitaliseNextWord] = useState(false);
  const [printView, setPrintView] = useState(false);
  const [poemTitle, setPoemTitle] = useState();
  const [poemSubtitle, setPoemSubtitle] = useState();
  const [pageNumber, setPageNumber] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [showShare, setShowShare] = useState();
  const [showAbout, setShowAbout] = useState();
  const [collectionActive, setCollectionActive] = useState();
  const punctuation = ',.()/[]?:;!—–-"\' ';
  const MAX_TEXTAREA_LENGTH = 3500;

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const poem = urlParams.get('p');
      const title = urlParams.get('t');
      const subtitle = urlParams.get('s');
      const number = urlParams.get('n');
      const elements = urlParams.get('e');
      if (poem) setNewPoem(JSON.parse(poem));
      if (title) setPoemTitle(title);
      if (subtitle) setPoemSubtitle(subtitle);
      if (number) setPageNumber(number);
      if (elements) {
        setPoemElements(JSON.parse(elements));
        setHideTextArea(true);
      }
      if (title || subtitle) {
        setShowOptions(true);
      }
    }
  }, []);

  const generateWords = (event, removeDuplicates, shuffle) => {
    let value = textareaValue
      .toLowerCase()
      .replace(/\n/g, ' ')
      .replace(/[-–—]/g, ' ')
      .replace('-', ' ')
      .replace(/['‘’]/g, "'")
      .replace(/[".—,-/#!$%^&*;:{}=\-_~()…@+?><[\]+]/g, '')
      .split(' ')
      .filter((n) => n)
      .sort();
    if (removeDuplicates) {
      value = [...new Set(value)];
    }
    if (shuffle) {
      value = shuffleArray(value);
    }
    setPoemElements(value);
    addNewLine();
    setHideTextArea(true);
  };
  const addToNewPoem = (newValue, addSpace) => {
    const newWord = capitaliseNextWord ? newValue.charAt(0).toUpperCase() + newValue.slice(1) : newValue;
    setCapitaliseNextWord(false);
    const newState = newPoem;
    const lastChildIndex = newState[activeLineNo].length - 1;

    if (newState[activeLineNo].length > 0 && addSpace && !punctuation.includes(newState[activeLineNo][lastChildIndex]))
      newState[activeLineNo].push(' ');
    newState[activeLineNo].push(newWord);
    setNewPoem([...newState]);
    setShowShare();
  };
  const addNewLine = () => {
    if (newPoem?.length) {
      let updatedPoem = newPoem;
      updatedPoem.splice(activeLineNo + 1, 0, []);
      setNewPoem(updatedPoem);
      setActiveLineNo(activeLineNo + 1);
    } else {
      setActiveLineNo(0);
      setNewPoem([[]]);
    }
    setShowShare();
  };
  const makeActiveLine = (e, index) => {
    e.stopPropagation();
    setActiveLineNo(index);
  };
  const deleteLastWord = () => {
    let editedNewPoem = newPoem;
    if (editedNewPoem[activeLineNo].length === 0) {
      editedNewPoem.splice(activeLineNo, 1);
      setActiveLineNo(editedNewPoem.length - 1);
    } else {
      editedNewPoem[activeLineNo].pop();
    }
    setNewPoem([...editedNewPoem]);
    setShowShare();
  };

  const clearAll = () => {
    setNewPoem();
    setTextareaValue('');
    setHideTextArea(false);
    setPoemElements([]);
    setActiveLineNo(0);
    setPageNumber();
    setPoemSubtitle();
    setPoemTitle();
    setShowOptions(false);
  };

  const startAgain = () => {
    if (!newPoem) {
      clearAll();
      return true;
    }
    if (window.confirm('You will lose your current poem, ok?')) {
      clearAll();
      return true
    }
    return false
  };
  const print = () => {
    setPrintView(true);
    setTimeout(() => {
      html2canvas(document.querySelector('.poetry-remixer--print-view .poem')).then((canvas) => {
        const button = document.createElement('button');
        button.innerText = 'Download';
        document.querySelector('.print-view__toolbar').prepend(button);
        document.querySelector('.print-view').appendChild(canvas);
        button.addEventListener('click', () => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/jpeg');
          a.download = 'image.jpeg';
          a.click();
        });
      });
    }, 100);
  };

  const randomExample = () => {
    if (startAgain()) {
      const randomNumber = Math.floor(Math.random() * examplePoems.length);
      setTextareaValue(examplePoems[randomNumber].text);
      setPoemTitle(`${examplePoems[randomNumber].title} - (Remix)`);
      setPoemSubtitle(`By ${examplePoems[randomNumber].subtitle} & ____`);
      setShowOptions(true);
    }
    
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  };

  const shareToEmail = () => {
    const poem = newPoem.map((line) => line.join('')).join('%0D%0A');
    const body = `${poem}`;
    window.location.assign(
      'mailto:submissionspoetica@gmail.com?Subject=' + encodeURIComponent(poemTitle) + '&body=' + body
    );
  };

  const shareToLink = () => {
    const params = {
      ...(newPoem && { p: JSON.stringify(newPoem) }),
      ...(poemTitle && { t: poemTitle }),
      ...(poemSubtitle && { s: poemSubtitle }),
      ...(pageNumber && { n: pageNumber }),
      ...(poemElements && { e: JSON.stringify(poemElements) })
    };
    const poemElementsStr = JSON.stringify(params);
    const urlSnip = new URLSearchParams(params).toString();
    setShowShare(`${document.location.host}?${urlSnip}`);
    setHideTextArea(true);
    setShowAbout(false);
    if (poemElementsStr.match(/[a-z]/i)) {
    } else {
      alert('Unable to share links to non latin characters, sorry');
    }
  };

  const hideTextInputFn = (hide) => {
    setShowShare();
    setShowAbout(false);
    setHideTextArea(hide);
  };

  const showAboutFn = () => {
    setShowAbout(!showAbout);
    setHideTextArea(true);
    setShowShare(false);
  };

  const deleteWord = (lineIndex, wordIndex) => {
    let editedNewPoem = newPoem;
    editedNewPoem[lineIndex].splice(wordIndex, 1);
    setNewPoem([...editedNewPoem]);
    setShowShare();
  };
  const capitaliseWord = (lineIndex, wordIndex, lowercase) => {
    let editedNewPoem = newPoem;
    const word = editedNewPoem[lineIndex][wordIndex];
    editedNewPoem[lineIndex].splice(
      wordIndex,
      1,
      lowercase ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
    );
    setNewPoem([...editedNewPoem]);
    setShowShare();
  };

  const wordInUse = (value) => newPoem.some((line) => line.includes(value));

  return (
    <div className={`poetry-remixer ${printView ? 'poetry-remixer--print-view' : ''}`}>
      <div className="poetry-remixer__input">
        <h1>Poetica</h1>

        <div className="poetry-remixer__nav">
          <button
            data-umami-event={`hide-textarea-${hideTextarea ? 'true' : 'false'}`}
            className="poetry-remixer__button"
            onClick={() => hideTextInputFn(!hideTextarea)}
          >
            {hideTextarea ? '↓' : '↑'} Text input
          </button>
          <button data-umami-event="start-again" className="poetry-remixer__button" onClick={startAgain}>
            Start again
          </button>
          <button
            data-umami-event="random"
            className="poetry-remixer__button"
            onClick={() => randomExample()}
          >
            Random
          </button>

          <button className="poetry-remixer__button" data-umami-event={`share-${showShare ? 'open' : 'closed'}`} onClick={() => (showShare ? setShowShare() : shareToLink())}>
            {!showShare ? '↓' : '↑'} Share
          </button>
          <button className="poetry-remixer__button" data-umami-event={`about-${showAbout ? 'open' : 'closed'}`} onClick={() => showAboutFn()}>
            {!showAbout ? '↓' : '↑'} About
          </button>
        </div>
        {showAbout && (
          <div className="poetry-remixer__about ">
            <h2>Instructions for use</h2>
            <p>
              Poetica is a tool for remixing poems or text, like you would a song, it provides you with a vocabulary of
              not your own words to create something new.
            </p>
            <p>
              1. Add some text and click generate
              <br />
              2. Poetica will separate words into buttons for you to playfully make a poem
              <br />
              3. When you like it click Share
            </p>
          </div>
        )}
        {showShare && (
          <div className="poetry-remixer__share">
            <h2>Export as image</h2>
            <p>Save your poem as an image</p>
            <button onClick={() => print()} data-umami-event="export-image">Export image</button>
            <h2>By link</h2>
            <p>
              You can use this very long link to save or share your progress, it'll keep everything except for the
              original inputted text
            </p>
            <div className="poetry-remixer__share__input-wrapper">
              <input type="text" value={showShare} readOnly />
              <button onClick={() => navigator.clipboard.writeText(showShare)} data-umami-event="copy-link-url" data-umami-event-value={showShare}>Copy</button>
            </div>

            <h2>By email</h2>
            <p>Send us what you made via email and the best ones we'll feature in the next collection.</p>
            <button onClick={() => shareToEmail()} data-umami-event="email-it">Email it</button>
          </div>
        )}
        {!hideTextarea && (
          <div className="poetry-remixer__text-input">
            <textarea
              id="poetry-remixer__textarea"
              className={`poetry-remixer__textarea ${hideTextarea ? 'poetry-remixer__textarea--hide' : ''}`}
              maxLength={MAX_TEXTAREA_LENGTH}
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
            ></textarea>

            <div className="poetry-remixer__text-input__buttons">
              <div className="poetry-remixer__button-wrapper">
                <button data-umami-event="generate" data-umami-event-length={textareaValue.length} className="poetry-remixer__button" onClick={(e) => generateWords(e, true)}>
                  Generate
                </button>
                <button className="poetry-remixer__button" data-umami-event="jumble-words" data-umami-event-value={textareaValue} onClick={(e) => generateWords(e, true, true)}>
                  Jumble words
                </button>
              </div>
              <div className="poetry-remixer__text-input__chars">
                {textareaValue ? textareaValue.length : 0} / {MAX_TEXTAREA_LENGTH}
              </div>
            </div>
          </div>
        )}
        {poemElements.length > 0 && (
          <div className="keyboard">
            <div className="keyboard__buttons keyboard__buttons--words">
              {poemElements.map((e, index) => (
                <button key={index} data-umami-event="add-word-to-poem" data-umami-event-value={e} onClick={() => addToNewPoem(e, true)}>
                  {e} {wordInUse(e) && '✓'}
                </button>
              ))}
            </div>
            <div className="keyboard__buttons keyboard__buttons--punctuation">
              {punctuation.split('').map((p, index) => (
                <button key={index} data-umami-event="add-punctuation-to-poem" data-umami-event-value={p}  onClick={() => addToNewPoem(p, false)}>
                  {p}
                </button>
              ))}
              <button data-umami-event="delete-last-word"  onClick={deleteLastWord}>Del</button>
              <button data-umami-event="capitalise-next-word" onClick={() => setCapitaliseNextWord(true)}>Caps</button>
              <button data-umami-event="new-line" onClick={addNewLine}>New line</button>
            </div>
          </div>
        )}
        <OptionsDropdown
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          poemTitle={poemTitle}
          setPoemTitle={setPoemTitle}
          poemSubtitle={poemSubtitle}
          setPoemSubtitle={setPoemSubtitle}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        <div className="poetry-remixer__collections">
          <h2>Submission collections</h2>
          <p>Example collection, to be replaced by submissions, share your poem with us via email and we'll include the best ones in the next collection</p>
          <button onClick={() => setCollectionActive(collectionOne)}>Collection 1</button>

          <button  data-umami-event="open-collection" data-umami-event-collection="february" onClick={() => setCollectionActive(february)}>February remix</button>
        </div>
      </div>

      <div className="poetry-remixer__output">
        <Poem
          title={poemTitle}
          subtitle={poemSubtitle}
          poem={newPoem}
          pageNumber={pageNumber}
          makeActiveLine={makeActiveLine}
          activeLineNo={activeLineNo}
          editable={true}
          deleteWord={deleteWord}
          capitaliseWord={capitaliseWord}
        />
        <div className="poetry-remixer__collections">
          <h2>Submission collections</h2>
          <button  data-umami-event="open-collection" data-umami-event-collection="collection-one" onClick={() => setCollectionActive(collectionOne)}>Collection 1</button>
          <button  data-umami-event="open-collection" data-umami-event-collection="february" onClick={() => setCollectionActive(february)}>February remix</button>
        </div>
      </div>
      {printView && <PrintView setPrintView={setPrintView} />}
      {collectionActive && <Collection poems={collectionActive} closeCollection={() => setCollectionActive()} />}
    </div>
  );
}

export default PoetryRemixer;
