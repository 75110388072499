import React from 'react';
import './options-dropdown.scss';

function OptionsDropdown({
  showOptions,
  setShowOptions,
  poemTitle,
  setPoemTitle,
  poemSubtitle,
  setPoemSubtitle,
  pageNumber,
  setPageNumber
}) {
  return (
    <div className="options-dropdown">
      <button className="options-dropdown__dropdown-title" onClick={() => setShowOptions(!showOptions)}>
        {!showOptions ? '↓' : '↑'} More format options
      </button>
      {showOptions && (
        <div className="options-dropdown__dropdown">
          <div className="options-dropdown__field">
            <label htmlFor="options-dropdown__title">Title</label>
            <input
              id="options-dropdown__title"
              className="options-dropdown__title"
              name="options-dropdown__title"
              placeholder="Poem title"
              value={poemTitle}
              onChange={(e) => {
                setPoemTitle(e.target.value);
              }}
            />
          </div>
          <div className="options-dropdown__field">
            <label htmlFor="options-dropdown__subtitle">Subtitle</label>
            <input
              className="options-dropdown__subtitle"
              id="options-dropdown__subtitle"
              name="options-dropdown__subtitle"
              placeholder="Author or subtitle"
              value={poemSubtitle}
              onChange={(e) => {
                setPoemSubtitle(e.target.value);
              }}
            />
          </div>
          <div className="options-dropdown__field">
            <label htmlFor="options-dropdown__page-number">Page number</label>
            <input
              type="number"
              id="options-dropdown__page-number"
              className="options-dropdown__page-number"
              placeholder="1"
              name="options-dropdown__page-number"
              value={pageNumber}
              onChange={(e) => {
                setPageNumber(e.target.value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default OptionsDropdown;
