import React from 'react';
import './App.scss';
import PoetryRemixer from './components/poetry-remixer';


function App() {
  return (
    <div className="App">
      <PoetryRemixer></PoetryRemixer>
      <footer>A fun little jolly from <a href="http://www.almaprojects.studio" rel="noreferrer" target="_blank">Alma Projects</a></footer>
    </div>
  );
}

export default App;
